<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M20.1666 15.51V18.26C20.1676 18.5153 20.1153 18.768 20.013 19.0019C19.9108 19.2358 19.7608 19.4458 19.5726 19.6184C19.3845 19.791 19.1624 19.9224 18.9206 20.0042C18.6787 20.0859 18.4225 20.1163 18.1682 20.0933C15.3475 19.7868 12.638 18.823 10.2574 17.2792C8.04257 15.8718 6.16479 13.994 4.7574 11.7792C3.20821 9.38778 2.24412 6.66509 1.94323 3.83167C1.92032 3.57819 1.95045 3.3227 2.03169 3.0815C2.11293 2.84029 2.2435 2.61864 2.4151 2.43066C2.5867 2.24268 2.79555 2.09249 3.02837 1.98965C3.26119 1.88681 3.51288 1.83358 3.7674 1.83334H6.5174C6.96226 1.82896 7.39354 1.9865 7.73085 2.27658C8.06815 2.56666 8.28847 2.9695 8.35073 3.41001C8.4668 4.29007 8.68206 5.15417 8.9924 5.98584C9.11573 6.31394 9.14242 6.67051 9.06931 7.01331C8.9962 7.35612 8.82636 7.67078 8.5799 7.92001L7.41573 9.08417C8.72066 11.3791 10.6208 13.2792 12.9157 14.5842L14.0799 13.42C14.3291 13.1736 14.6438 13.0037 14.9866 12.9306C15.3294 12.8575 15.686 12.8842 16.0141 13.0075C16.8457 13.3178 17.7098 13.5331 18.5899 13.6492C19.0352 13.712 19.4419 13.9363 19.7326 14.2794C20.0233 14.6225 20.1777 15.0605 20.1666 15.51Z"
        stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
export default {
  name: 'PhoneIcon'
}
</script>

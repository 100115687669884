<script setup>
import TopBar from "~/components/organisms/TopBar.vue";
import Header from "~/components/organisms/Header.vue";
import {defineAsyncComponent} from 'vue'
import {useStartupStore} from "~/store/sturtupStore";
import GreyBlock from "~/components/templates/GreyBlock.vue";
import {useRoute} from "vue-router";
import Text from "~/components/atoms/Text.vue";
import PhoneIcon from "~/components/icons/PhoneCartIcon.vue";
import MessageIcon from "~/components/icons/MessageIcon.vue";
import EnvelopeCartIcon from "~/components/icons/EnvelopeCartIcon.vue";
import Reviews from "~/components/organisms/Reviews.vue";
import {useCustomFetch} from '~/composables/customFetch.js';

const route = useRoute();
const startupStore = useStartupStore();

const SideMenu = defineAsyncComponent({
  loader: () => import('~/components/organisms/SideMenu.vue'),
})

useHead({
  meta: [
    {name: 'robots', content: 'noindex, nofollow'}
  ],
})

const reviews_data = await useCustomFetch('/reviews')
const {reviews} = reviews_data.data.value

</script>

<template>
  <div class="main-layout">
    <GreyBlock :padding="0">

      <div class="g-16 the-same-gap">
        <header>
          <TopBar v-if="!$device.isMobile"/>
          <Header mode="checkout"/>
        </header>
        <SideMenu v-if="startupStore.isMenuInitialised"/>
        <main>
          <div class="g-128 shorter-gap">
            <div class="checkout-container g-32">
              <div class="tabs row full-width" v-if="route.path !== '/checkout/thank-you/'">
                <div>
                  <nuxt-link class="link hbb"
                             :class="{old:['/checkout/payment/','/checkout/thank-you/'].includes(route.path)}"
                             to="/checkout">
                    <Text text-alignment="center" uppercase>Checkout</Text>
                  </nuxt-link>
                </div>
                <div>
                  <nuxt-link class="link hbb disabled" to="/checkout/payment">
                    <Text text-alignment="center" uppercase>Betalning</Text>
                  </nuxt-link>
                </div>
                <div>
                  <nuxt-link disabled @click.stop.prevent="false" to="/checkout/thank-you" class="link hbb disabled">
                    <Text text-alignment="center" uppercase>Finish</Text>
                  </nuxt-link>
                </div>
              </div>
              <slot/>
            </div>

            <!--Reviews-->
            <div class="container">
              <Reviews :data="reviews"/>
            </div>

            <!--Footer-->
            <div class="checkout-footer flex-center column-mobile">
              <div class="continent left">
                <img loading="lazy" src="/svg/continent-2.svg"/>
              </div>
              <div class="footer-content flex-center column-mobile g-24">
                <Text tag="strong" class="title">Behöver du hjälp med ditt köp?</Text>

                <div class="row full-width g-32 column-mobile">
                  <div class="contact-block flex-center">
                    <nuxt-link :to="$t('menu.items.contact-us.link')" class="flex-center g-16 clickable">
                      <EnvelopeCartIcon/>
                      <Text tag="strong" text-alignment="center">Skicka oss ett mail</Text>
                      <Text text-alignment="center">{{ $t('menu.items.contact-us.title') }}</Text>
                    </nuxt-link>
                  </div>
                  <div class="contact-block flex-center">
                    <nuxt-link :to="$t('menu.items.contact-us.link')" class="flex-center g-16 clickable">
                      <MessageIcon/>
                      <Text tag="strong" text-alignment="center">Chatta med oss</Text>
                      <Text text-alignment="center">Svarar inom några minuter</Text>
                    </nuxt-link>
                  </div>
                  <div class="contact-block flex-center">
                    <nuxt-link to="tel:+46406083480" class="flex-center g-16 clickable">
                      <PhoneIcon/>
                      <Text tag="strong" text-alignment="center">Ring oss</Text>
                      <Text text-alignment="center">+46406083480</Text>
                    </nuxt-link>
                  </div>
                </div>
              </div>
              <div class="continent right">
                <img loading="lazy" src="/svg/continent-1.svg"/>
              </div>
            </div>
          </div>
        </main>
      </div>
    </GreyBlock>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/mixins.scss";

main {
  @include smd {
    padding: 0 15px;
  }
}

.main-layout {
  @include mobile {
    margin-top: -24px;
  }
}

.container {
  @include mobile {
    padding: 0;
  }
}

.link {
  color: $disabled-text;

  &.old, &.router-link-active {
    color: $dark-black;
  }
}

.checkout-container {
  width: 1218px;
  max-width: 100%;
  margin: auto;
}

.checkout-footer {
  @include smd {
    margin: 0 -16px;
  }
}

.footer-content {
  width: 717px;
  margin: auto 171px;
  min-height: 556px;
  max-width: 100%;

  @include smd {
    padding-bottom: 40px;
  }

  .title {
    font-size: 26px;

    @include smd {
      font-size: 20px;
      z-index: 2;
      margin-bottom: 8px;
    }
  }

  .contact-block {
    min-height: 158px;
    min-width: 218px;
    background: $white;
    box-shadow: 0 4px 45px 0 #F9F9F9;
    z-index: 2;

    a {
      display: flex;
      flex-direction: column;
    }

    svg {
      stroke: $black;
      height: 22px;
    }

    strong {
      font-size: 16px;
    }
  }
}

.disabled {
  pointer-events: none;
}

.continent {
  position: absolute;
  top: 0;
  bottom: 0;
  opacity: 0.4;

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }

  img {
    width: 100%;

    @include smd {
    }
  }
}

</style>
